body {
  margin: 0;
  padding: 0;
  background-color:darkslategrey;
  color: #fff;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

h1 {
  text-align: center;
}

table {
  margin: 0 auto;
  width: 400px;
  height: 400px;
  border-collapse: collapse;
}

td {
  color: #fff;
  text-align: center;
  text-shadow: 0 0 5px #000;
  cursor: pointer;
  vertical-align: middle;
  border: none;
  background-color: rgba(114, 136, 136, .4) ;
  background-repeat: no-repeat;
}

.button, select {
  display: block;
  margin: 1rem auto;
  height: 2.4rem;
  width: 12rem;
  padding: 0 1.5rem;
  background-color: #728888; 
  border: none;  
  font-weight: 800;
}

.button:hover,
select:hover {
  color: rgba(255, 255, 255, 1);  
  background-color: rgba(114, 136, 136, .7) ;
}

.button, h2, select {
  border-radius: 5rem;
  color: rgba(255, 255, 255, .8);  
  background-color: rgba(114, 136, 136, .5) ;
  font-family: Verdana, Geneva, Tahoma, sans-serif
}

label {
  display: block;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  margin-bottom: 1rem;
}

h2 {
  text-align: center;
  margin: 0 auto;
  padding: 1.5rem;
  max-width: 400px;
  height: 2.5rem;
  color: rgba(255, 255, 255, 1);
}

.imageButtonsBox {
  display: block;
  max-width: 400px;
  margin: 1rem auto;
}

.imageButton {
  display: block;
  /* text-align: center!important; */
  margin: 1rem .5rem;
  height: 4rem;
  width: 4rem;
  border: none;
  background-size: contain;
  float: left;
  cursor: pointer;

}

.imageButtonsBox::after {
  content: "";
  clear: both;
  display: table;
}

footer {
  display: block;
  margin: 1rem 0;
}

.copyright,
.version {
  text-align: center;
  margin: 0;
  font-size: .8rem;
}

.copyright a {
  text-decoration: none;
  color: inherit;
}

.copyright a:hover {
  color: #aaa;
}